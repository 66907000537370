@media (max-width: 1399px) {
  .trainer-img {
    width: 80% !important;
  }

  .nav-bar-list {
    gap: 5px !important;
  }

  .Banner-board {
    position: relative;
    right: 0px;
    top: 35px;
  }

  .choice-content>div {
    width: 38% !important;
  }

  .choice-1>div {
    left: 10px !important;
  }

  .choice-2>div {
    right: 10px !important;
  }

  .choice-3>div {
    left: 10px !important;
    bottom: 55px;
  }

  .choice-4>div {
    right: 10px !important;
    bottom: 55px;
  }

  .star-1 {
    left: 270px;
  }
  .layer-mid::after {
    bottom: 0;
    content: "";
    height: 100px;
    left: 80px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 330px;
    width: 60px;
  }
}

@media (max-width: 1199px) {
  .logo svg {
    width: 70px !important;
  }

  .Banner-board {
    position: relative;
    right: 0px;
    top: 35px;
  }

  header .Social-links {
    display: none !important;
  }

  footer .Social-links {
    display: flex !important;
  }

  .video-react-controls-enabled {
    height: 320px !important;
  }

  .trainer-img {
    width: 100% !important;
  }

  p {
    font-size: 13px;
  }

  .tainer-content-list {
    padding: 0 6px;

    &>span {
      width: 45px;
    }

  }

  // .banner-content {
  //   left: 100px !important;
  //   bottom: 120px !important;
  // }

  .choice-content>div {
    width: 37% !important;

    & .main-heading {
      font-size: 28px !important;
    }
  }

  .choice-1>div {
    left: 0 !important;
  }

  .choice-3>div {
    left: 0px !important;
    bottom: 40px !important;
  }

  .choice-2>div {
    right: 0 !important;
  }

  .choice-4>div {
    right: 0px !important;
    bottom: 10px !important;
  }



  .choice-5>div {
    bottom: -90px !important;
  }

  .slider-bg .border-image>span {
    bottom: -150px !important;
  }

  .service-vector {
    position: relative;
    bottom: 50px !important;
  }


  // .footer-content>svg {
  //   width: 150px !important;
  // }

  .footer-logo svg {
    width: 70px !important;
  }

  .star-1 {
    left: 150px;
  }
  .layer-mid::after {
    bottom: 0;
    content: "";
    height: 100px;
    left: 40px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 330px;
    width: 60px;
  }
}

@media (max-width: 991px) {
  .social-link {
    display: none;
  }

  .nav-bar-list {
    display: none !important;
  }

  .toggleBtn {
    margin: 0 5px;
    display: block !important;
    cursor: pointer;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
  }

  .toggleBtn svg {
    color: var(--darkGradient);
  }

  .showToggle {
    position: absolute !important;
    top: 86px;
    left: 0;
    background: var(--darkGradient) !important;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
    color: var(--white) !important;
    width: 100% !important;
    display: block !important;
    box-shadow: 0px 5px 15px #dbdbdbd6;
  }

  .showToggle div {
    border-bottom: 1px solid var(--white);
    outline: none;
    width: 100%;
    padding: 20px 10px;
  }

  .showToggle a:hover {
    color: var(--themeColor) !important;
    -webkit-text-fill-color: var(--white) !important;
  }

  .showToggle .active {
    color: var(--themeColor) !important;
    -webkit-text-fill-color: var(--white) !important;
    background: var;
  }

  .showToggle .nav-bar-list a {
    // color: var(--themeColor);
    background: none !important;

  }

  .nav-bar-list a.active::after,
  .nav-bar-list a.active::before,
  .nav-bar-list a:hover::after,
  .nav-bar-list a:hover::before {
    background: none !important;
  }

  .sticky.py-2 {
    transition: all 0.4s ease-in-out;
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
  }

  // nenu-responsive end

  .layers {
    height: auto !important;
    min-height: 550px !important;
    width: 100%;
  }

  // .layer-mid-x-2 {
  //   position: relative;
  //   left: -210px;
  // }

  // .text-styled {
  //   position: relative;
  //   right: 9px !important;
  //   top: 15px !important;
  //   font-size: 200px !important;
  // }
  .banner-content {
    position: relative;
    left: 0px !important;
    bottom: 80px !important;
  }

  .Banner-board {
    position: relative;
    right: 10px !important;
    top: 0px !important;
  }

  // .caption {
  //   font-size: 25px !important;
  // }

  .about-content .w-50 {
    width: 85% !important;
  }

  .about-content {
    width: 100% !important;
  }

  .about-house {
    height: 230px !important;
    width: 250px !important;
    right: -220px !important;
  }

  .house-bird {
    right: 0px !important;
    top: 250px !important;
    width: 190px !important;
  }

  .about-content {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
    gap: 40px !important;
  }

  .service-vector {
    width: 300px !important;
    bottom: 0 !important;
  }

  #trainer>.container>.align-items-center {
    flex-direction: column-reverse;
  }

  .border-image>span {
    bottom: -75px !important;
  }

  .trainer-col {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .row-gap {
    margin-top: 60px;
  }

  .heading-main {
    font-size: 50px !important;
  }

  .heading-mini {
    font-size: 20px !important;
  }

  .bach-img {
    display: none !important;
  }

  .bach-logo {
    text-align: end;
  }

  .achived-content-wrapper {
    &.sub-heading {
      font-size: 30px !important;
    }

    &>.heading-main {
      font-size: 54px !important;
    }

    &>.heading-mini {
      font-size: 36px !important;
    }

  }

  .choice-ball {
    display: none !important;
  }

  .choice-content>div {
    position: relative !important;
    width: 100% !important;
    bottom: unset !important;
  }

  .choice-1>div,
  .choice-3>div {
    flex-direction: row !important;
    text-align: justify !important;
  }

  .choice-4>div {
    bottom: auto !important;
  }

  .choice-5 {

    &>div {
      flex-direction: row !important;
      text-align: justify !important;
      justify-content: center !important;
      align-items: baseline !important;

      &>.choice-img-choice-5 {
        width: 140px;
        text-align: center;
        top: 10px;
        position: relative;
      }

    }
  }


  .chosse-bg {
    padding: 60px 0 20px;
  }

  .upper-grid {
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
    gap: 15px;
    margin-top: 30px;

    &>div {
      width: 50%;

      &>.gallery-items {
        width: 100%;
        height: 300px;

        &>img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px !important;
        }
      }
    }
  }


  .gallery-grid {
    grid-template-columns: repeat(2, 1fr) !important;
    grid-template-rows: repeat(2, 160px);

    & .gallery-img-wraping {

      & img {
        border-radius: 10px !important;
      }

    }
  }

  .partner-grid {
    grid-template-columns: repeat(4, 1fr) !important;
    grid-template-rows: repeat(4, 130px) !important;
  }

  .containerContact {
    padding-top: 20px;
  }

  .contact-box {
    padding-bottom: 30px;
  }

  iframe {
    width: 100% !important;
  }

  .footer-content {
    flex-direction: column !important;
    gap: 10px !important;
  }

  .border-top-line {
    padding: 15px 0 0 !important;
    bottom: 15px !important;
  }

  .contact-Us {
    order: 1 !important;
  }

  .contect-with-you {
    position: relative !important;
    z-index: 1;
  }

  .star-1 {
    width: 450px;
    left: 100px;
  }
  .layer-mid::after {
    bottom: 0;
    content: "";
    height: 80px;
    left: 40px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 220px;
    width: 60px;
  }
}

@media (max-width: 768px) {

  .about-content {
    width: 100% !important;
  }

  .caption-sub-heading {
    font-size: 18px !important;
  }

  .caption-heading-main {
    font-size: 24px !important;
  }

  .caption-sm-heading {
    font-size: 28px !important;
  }

  .heading-main {
    font-size: 44px !important;
  }

  .heading-mini {
    font-size: 18px !important;
  }

  .Banner-board {
    right: 0px !important;
    top: -30px !important;
  }

  .house-bird {
    width: 150px !important;
  }

  .trainer-img {
    width: 30% !important;
  }

  .tainer-content-list>span {
    width: 20px;
  }

  .bach-logo {
    text-align: center;
  }

  .border-image>span {
    bottom: -55px !important;
  }

  .choice-5>.choice-img-choice-5 {
    width: 110px;
  }

  .slider-bg .border-image>span {
    bottom: -130px !important;
  }

  .gallery-grid {
    grid-template-columns: repeat(3, 1fr) !important;
    grid-template-rows: repeat(1, 160px) !important;
    gap: 20px !important;
  }

  .img-item-7 {
    position: relative;
    grid-column: 1/5 !important;
    grid-row: 1/3 !important;

    &::before {
      content: '+4';
      position: absolute;
      width: 100%;
      height: 100%;
      background: #00000054;
      z-index: 1;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      color: wheat;
      cursor: pointer;

    }
  }

  .partner-grid {
    grid-template-columns: repeat(3, 1fr) !important;
    grid-template-rows: repeat(6, 130px) !important;
  }

  .footer-list {
    display: none !important;
  }

  .footer-revised {
    text-align: center !important;
  }

  .star-1 {
    width: 350px;
    left: 100px;
  }
  .layer-mid::after {
    bottom: 0;
    content: "";
    height: 80px;
    left: 100px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 220px;
    width: 60px;
  }
}

@media (max-width: 576px) {

  .nav-bar button,
  .about-social-link {
    display: none !important;
  }

  .layers {
    height: auto !important;
    min-height: 500px !important;
    width: 100%;
  }

  .about-vector {
    width: 70px !important;
  }

  .caption-sub-heading {
    font-size: 16px !important;
  }

  .heading-main {
    font-size: 40px !important;
  }

  .caption-heading-main {
    font-size: 21px !important;
  }

  .caption-sm-heading {
    font-size: 24px !important;
  }

  p {
    font-size: 14px !important;
  }

  .achived-content-wrapper>.heading-mini {
    font-size: 26px !important;
  }

  .achived-content-wrapper>.heading-main {
    font-size: 50px !important;
  }

  .about-house {
    display: none !important;
  }

  button {
    font-size: 12px !important;
  }

  .banner-content {
    position: relative;
    left: 30px !important;
    bottom: 80px !important;
  }

  .Banner-board>div {
    width: 180px !important;
    height: 145px !important;
  }

  .Banner-board>div::before {
    top: 8px;
    width: 150px;
    height: 136px;
  }

  .Banner-board>div>button {
    width: 130px !important;
    left: 10px !important;
    top: 14px !important;
  }

  .border-image>span {
    bottom: -35px !important;
  }

  .about-content {
    padding-top: 100px !important;
    padding-bottom: 70px !important;
  }

  .achived-content-wrapper {
    justify-content: center !important;
    align-items: center !important;
  }

  #chooseUs .heading-main {
    text-align: center !important;
  }

  #trainer>.container>.align-items-center {
    flex-direction: column-reverse;
    align-items: center !important;
    justify-content: center !important;
  }

  .trainer-col {
    position: relative;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    bottom: 50px !important;
  }

  .service-content {
    text-align: center;
  }

  .choice-wrapper {
    position: relative;
    padding: 0 15px 50px !important;
  }

  .slider-bg .border-image>span {
    bottom: -50px !important;
  }

  .slider-bg {
    padding: 20px 0 30px;
  }

  .gallery-bg {
    padding: 60px 0 60px 0 !important;
  }

  .gallery-content {
    text-align: justify;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .client-content {
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
  }

  .client-bg {
    padding: 50px 0;
  }

  .containerContact {
    padding-top: 30px !important;
  }

  .row-gap {
    row-gap: 30px;
  }

  .footer-revised {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .house-bird {
    display: none;
  }

  .loading-btn {
    top: 50% !important;
    height: 9% !important;
    width: 100% !important;
  }

  .about-animate {
    text-align: justify;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  // .choice-content .main-heading {
  //   text-align: left;
  // }

  .contect-with-us {
    position: relative;
    z-index: 1 !important;
  }

  .Banner-board {
    display: none !important;
  }
  .layer-mid::after {
    bottom: 0;
    content: "";
    height: 60px;
    left: 100px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 200px;
    width: 60px;
  }
}

@media (max-width: 480px) {
  .img-item-8 {
    grid-column: 6/8 !important;
    grid-row: 1/3 !important;
  }

  .img-item-7 {
    grid-column: 1/6 !important;
    grid-row: 1/3 !important;
  }

  .footer-revised {
    text-align: center;
  }

  .whatsapp,
  .change-Btn {
    width: 60px !important;
  }

  choice-content .main-heading {
    font-size: 24px !important;
    text-align: left !important;
  }


  .partner-grid {
    grid-template-columns: repeat(2, 1fr) !important;
    grid-template-rows: repeat(8, 130px) !important;
  }

  .contact-info {
    justify-content: flex-start !important;
    flex-wrap: wrap !important;
    text-align: justify !important;
  }

  .common-space {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .star-1 {
    width: 300px;
    left: 100px;
    top: 180px;
  }

}

@media (max-width: 420px) {
  .star-1 {
    width: 300px;
    left: 30px;
    top: 180px;
  }

  .trainer-img {
    width: 80% !important;
  }

  .border-image>span {
    bottom: -15px !important;
  }

  .choice-5>.choice-img-choice-5 {
    width: 190px !important;
  }

  .banner-content {
    left: 0px !important;
    bottom: 80px !important;
  }

  .Banner-board>div {
    width: 160px !important;
    height: 135px !important;
  }

  .btn-text {
    padding: 8px 4px !important;
  }
  .layer-mid::after {
    bottom: 0;
    content: "";
    height: 60px;
    left: 50px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 200px;
    width: 60px;
  }
}

@media (max-width: 380px) {
  .star-1 {
    width: 280px;
    left: 20px;
    top: 200px;
  }

  .partner-grid {
    grid-template-columns: repeat(1, 1fr) !important;
    grid-template-rows: repeat(16, 130px) !important;
  }


}

/***************** RTL DIrection*************************/

@media (max-width: 1399px) {

  .rtl .heading-main {
    font-size: 55px !important;

  }


}

@media (max-width: 1199px) {

  .rtl .footer-logo svg {
    width: 70px !important;
  }

  .rtl .choice-4>div {
    right: 0px !important;
    bottom: 50px !important;
  }

  .rtl .choice-5>div {
    bottom: -110px !important;
  }
}

@media (max-width: 991px) {
  // .rtl .text-styled {
  //   font-size: 150px !important;
  //   top: 0px !important;
  // }

  .rtl .banner-content {
    left: auto !important;
    right: auto !important;
    bottom: 100px !important;
  }

  .rtl .Banner-board {
    right: 0px !important;
    top: 0px !important;
  }

  .rtl .choice-content>div {
    direction: rtl !important;
  }

  .rtl .choice-2>div {
    text-align: right !important;
  }

  .rtl .choice-3>div {
    bottom: unset !important;
  }

  .rtl .choice-4>div {
    text-align: right !important;
    right: 0px !important;
    bottom: unset !important;
  }

  .rtl .choice-5>div {
    bottom: unset !important;
    justify-content: flex-start !important;
  }

  .rtl .upper-grid>div>.gallery-img-wraping>span>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px !important;
  }

  .rtl .gallery-grid .gallery-img-wraping img {
    border-radius: 10px !important;
  }

  .rtl .bAQxrs .img-item-8 .item-8 {
    border-radius: 10px !important;
  }

}

@media (max-width: 768px) {

  .rtl .heading-main {
    font-size: 44px !important;
  }

}

@media (max-width: 576px) {

  .rtl .Banner-board>div>button {
    left: auto;
    right: 40px !important;
  }

  .rtl .trainer-wrapper {
    justify-content: unset !important;
    gap: 10px !important;
  }

  .rtl .choice-5>div>.choice-img-choice-5 {
    width: 80px;
    text-align: right !important;
  }

  .rtl .choice-content>div .main-heading {
    font-size: 22px !important;
  }

}

@media (max-width: 480px) {
  .rtl .contact-text {
    color: var(--white);
    text-align: end !important;
  }

  .rtl .trainer-img {
    width: 55% !important;
  }

}

@media (max-width: 420px) {
  .rtl .contact-text {
    color: var(--white);
    text-align: end !important;
  }

  .rtl .choice-content .main-heading {
    font-size: 28px !important;
    text-align: right !important;
  }

  .rtl .choice-5>.choice-img-choice-5 {
    width: 110px !important;
  }

  .rtl .Banner-board>div>button {
    left: auto !important;
    right: 20px !important;
  }

  .rtl .trainer-img {
    width: 60% !important;
  }

}