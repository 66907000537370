

@font-face {
  font-family: 'HeadingNow-73Book';
  src: url('../fonts/HeadingNow-73Book.woff2') format('woff2'),
    url('../fonts/HeadingNow-73Book.woff') format('woff'),
    url('../fonts/HeadingNow-73Book.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HeadingNow-74Regular';
  src: url('../fonts/HeadingNow-74Regular.woff2') format('woff2'),
    url('../fonts/HeadingNow-74Regular.woff') format('woff'),
    url('../fonts/HeadingNow-74Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HeadingNow-75Medium';
  src: url('../fonts/HeadingNow-75Medium.woff2') format('woff2'),
    url('../fonts/HeadingNow-75Medium.woff') format('woff'),
    url('../fonts/HeadingNow-75Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Soccer League';
  src: url('../fonts/SoccerLeague-Regular.woff2') format('woff2'),
    url('../fonts/SoccerLeague-Regular.woff') format('woff'),
    url('../fonts/SoccerLeague-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/****************Arabic Fonts***************/

@font-face {
  font-family: "Noto Kufi Arabic Thin";
  src: url("../fonts/arabic/NotoKufiArabic-Thin.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic ExtraLight";
  src: url("../fonts/arabic/NotoKufiArabic-ExtraLight.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic Light";
  src: url("../fonts/arabic/NotoKufiArabic-Light.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic Regular";
  src: url("../fonts/arabic/NotoKufiArabic-Regular.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic Medium";
  src: url("../fonts/arabic/NotoKufiArabic-Medium.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic SemiBold";
  src: url("../fonts/arabic/NotoKufiArabic-SemiBold.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic Bold";
  src: url("../fonts/arabic/NotoKufiArabic-Bold.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic ExtraBold";
  src: url("../fonts/arabic/NotoKufiArabic-ExtraBold.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic Black";
  src: url("../fonts/arabic/NotoKufiArabic-Black.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

:root {



  --headingFont: "Soccer League";
  
  --book: "HeadingNow-73Book";
  --medium: "HeadingNow-75Medium";
  --regular: "HeadingNow-74Regular";
 


  --arThin: "Noto Kufi Arabic Thin";
  --arExtraLight: "Noto Kufi Arabic ExtraLight";
  --arLight: "Noto Kufi Arabic Light";
  --arRegular: "Noto Kufi Arabic Regular";
  --arMedium: "Noto Kufi Arabic Medium";
  --arSemiBold: "Noto Kufi Arabic SemiBold";
  --arBold: "Noto Kufi Arabic Bold";
  --arExtraBold: "Noto Kufi Arabic ExtraBold";
  --arBlack: "Noto Kufi Arabic Black";
}