@import 'variables';
@import 'fonts';

html {
    position: relative;
    width: 100%;
}

.main-artical {
    overflow-x: hidden;
}

// * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//     overflow-x: hidden;
// }

body {
    position: relative !important;
    font-family: var(--regular);
    font-style: normal;
    font-size: 15px;
    background: #B3CFD4;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

img {
    max-width: 100%;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

li {
    list-style: none
}

a {
    text-decoration: none;
    cursor: pointer;
}

a,
.btn {
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

a:focus,
.btn:focus {
    text-decoration: none;
    outline: none
}

p {
    font-size: 15px;
    font-family: var(--regular);
    color: var(--themeColor);
    // padding-bottom: 15px;
    // background: var(--themeColor);
    // -webkit-text-fill-color: transparent;
    // -webkit-background-clip: text;
}

*::-moz-selection {
    background: var(--themeColor);
    color: #fff;
    text-shadow: none
}

*::-moz-selection {
    background: var(--themeColor) none repeat scroll 0 0;
    color: #fff;
    text-shadow: none
}

::selection {
    background: var(--themeColor);
    color: #fff;
    text-shadow: none
}

.themeColor {
    color: var(--themeColor);
}

.darkColor {
    color: var(--darkColor);
}


.whiteColor {
    color: var(--white);
}

.darkGradient {
    background: var(--darkGradient);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;

}

.lightGradient {
    background: var(--lightGradient);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.bg-white {
    background: var(--white);
}

.bg-themeColor {
    background: var(--themeColor);
}

.bg-darkColor {
    background: var(--darkColor);
}

/* header-btn */
.header-btn {
    overflow: hidden;
}

.header-btn::after {
    height: 100%;
    left: -35% !important;
    top: 0 !important;
    transform: skew(50deg);
    transition-duration: 0.6s !important;
    transform-origin: top left;
    width: 0;
}

.header-btn:hover:after {
    height: 100%;
    width: 135%;
}

.common-space {
    padding-top: 50px;
    padding-bottom: 50px;
}

.common-space-over {
    margin-top: 100px;
    margin-bottom: 100px;
}

.about-bg {
    position: relative;
    background-image: url('../images/png/about-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
}

.service-bg {
    background-image: url('../images/png/service-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: bottom;
}

.achieve-bg {
    position: relative;
    background-image: url('../images/png/achieve-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .lazy-load-image-background {
        position: relative !important;
        width: 100% !important;
    }
}

.chosse-bg {
    background-image: url('../images/png/choose-us-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 60px 0 120px;
    position: relative;
}

.slider-bg {
    background-image: url('../images/png/slider-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 60px 0 80px;
    position: relative;

    & .border-image>span {
        width: 100%;
        position: absolute;
        bottom: -168px;
        z-index: 1;
    }
}

.gallery-bg {
    background-image: url('../images/png/gallery-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    padding: 120px 0 0;
    position: relative;

    // & #gallery {
    //     padding: 120px 0 0;
    // }
}

.client-bg {
    padding: 80px 0;
}

.contact-bg {
    position: relative;
    background-image: url('../images/png/contact-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.border-bottom-line {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: 92%;
        height: 1%;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        // background-color: var(--darkColor);
        border: 0.5px solid #79b5ce7d;
    }
}

.border-image {
    position: relative;

    &>span {
        width: 100%;
        position: absolute;
        bottom: -65px;
        z-index: 1;

        &>img {
            width: 100%;

        }
    }
}


.border-top-line {
    position: relative;
    margin-top: 25px;
    padding: 15px 0 10px;

    &::before {
        content: '';
        position: absolute;
        width: 90%;
        height: 1%;
        left: 0;
        right: 0;
        top: 0;
        margin: auto;
        border: 0.5px solid rgba(121, 181, 206, 0.4901960784);
    }
}

.social-link {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 30px;
    margin: auto;
    display: flex;
    gap: 15px;
    z-index: 1000;
    height: 200px;
    flex-wrap: wrap;
    width: 60px;

    & svg {
        cursor: pointer;
    }
}

.Banner-board {
    position: relative;
    right: 50px;
    top: 30px;
    z-index: 2;

    &>div {
        width: 178px;
        height: 162px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url('../images/png/banner-btn.png');
        }

        &>button {
            position: relative;
            left: 14px;
            top: 10px;
            width: 150px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }


}



/* Scrollbar Styling */
.sale-content-scroll::-webkit-scrollbar {
    width: 6px;
}

.sale-content-scroll::-webkit-scrollbar-track {
    position: relative;
    background-color: #ffe6a864;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    backdrop-filter: blur(5px);
}

.sale-content-scroll::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: var(--DarkText);

    &::before {
        content: '';
        position: absolute;
        width: 15px;
        height: 10px;
        background-color: var(--DarkText);
    }
}

iframe {
    margin-top: 35px;
    width: 100%;
    height: 420px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

/***************** Parallex effectg css*************************/
:root {
    --index: calc(1vw + 1vh);
    --transition: transform 0.75s cub-bezier(0.075, 0.5, 0, 1);
}

.content {
    will-change: transform;
}

.layers {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    height: 100vh;
}

.layer-head {
    z-index: 1;
    transform: translate3d(0, calc(var(--scrollTop)/ 2), 0);
    -webkit-transform: translate3d(0, calc(var(--scrollTop)/ 2), 0);
    -moz-transform: translate3d(0, calc(var(--scrollTop)/ 2), 0);
    -ms-transform: translate3d(0, calc(var(--scrollTop)/ 2), 0);
    -o-transform: translate3d(0, calc(var(--scrollTop)/ 2), 0);
    transition: var(--transition);
    -webkit-transition: var(--transition);
    -moz-transition: var(--transition);
    -ms-transition: var(--transition);
    -o-transition: var(--transition);
    will-change: transform;
}

.caption {
    transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -webkit-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -moz-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -ms-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -o-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
}

.img-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    background-size: cover !important;
    background-position: center !important;
    will-change: transform;
    z-index: 2;
    transition: var(--transition);
    -webkit-transition: var(--transition);
    -moz-transition: var(--transition);
    -ms-transition: var(--transition);
    -o-transition: var(--transition);
}

.layer-base {
    transform: translate3d(0, calc(var(--scrollTop)/ 1.6), 0);
    -webkit-transform: translate3d(0, calc(var(--scrollTop)/ 1.6), 0);
    -moz-transform: translate3d(0, calc(var(--scrollTop)/ 1.6), 0);
    -ms-transform: translate3d(0, calc(var(--scrollTop)/ 1.6), 0);
    -o-transform: translate3d(0, calc(var(--scrollTop)/ 1.6), 0);
    z-index: -1;
}

.layer-mid {
    // position: relative;
    transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -webkit-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -moz-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -ms-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -o-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);

    &:after {
        content: "";
        position: absolute;
        top: 300px;
        bottom: 0;
        right: 0;
        left: 170px;
        margin: auto;
        width: 60px;
        height: 100px;
        background-image: url(../images/png/one.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.layer-mid-up {
    transform: translate3d(0, calc(var(--scrollTop)/ -6), 0);
    -webkit-transform: translate3d(0, calc(var(--scrollTop)/ -6), 0);
    -moz-transform: translate3d(0, calc(var(--scrollTop)/ -6), 0);
    -ms-transform: translate3d(0, calc(var(--scrollTop)/ -6), 0);
    -o-transform: translate3d(0, calc(var(--scrollTop)/ -6), 0);
}

.layer-front {
    transform: translate3d(0, calc(var(--scrollTop)/ 4.5), 0);
    -webkit-transform: translate3d(0, calc(var(--scrollTop)/ 4.5), 0);
    -moz-transform: translate3d(0, calc(var(--scrollTop)/ 4.5), 0);
    -ms-transform: translate3d(0, calc(var(--scrollTop)/ 4.5), 0);
    -o-transform: translate3d(0, calc(var(--scrollTop)/ 4.5), 0);
}

.artical-main {
    --mainarticle-transform: translate3d(0, calc(var(--scrollTop)/ -7.6));
    // position: relative;
    top: -2px;
    z-index: 10;
}

.layer-mid-2 {
    transform: translate3d(calc(var(--scrollTop)/ 5.6), 0, 0);
    -webkit-transform: translate3d(calc(var(--scrollTop)/ 5.6), 0, 0);
    -moz-transform: translate3d(calc(var(--scrollTop)/ 5.6), 0, 0);
    -ms-transform: translate3d(calc(var(--scrollTop)/ 5.6), 0, 0);
    -o-transform: translate3d(calc(var(--scrollTop)/ 5.6), 0, 0);
}

// .layer-mid-X {
//     transform: translate3d(calc(var(--scrollTop)/ -5), 0, 0);
//     -webkit-transform: translate3d(calc(var(--scrollTop)/ -5), 0, 0);
//     -moz-transform: translate3d(calc(var(--scrollTop)/ -5), 0, 0);
//     -ms-transform: translate3d(calc(var(--scrollTop)/ -5), 0, 0);
//     -o-transform: translate3d(calc(var(--scrollTop)/ -5), 0, 0);
// }

// .layer-mid-x-2 {
//     position: relative;
//     left: -370px;
//     transform: translate3d(calc(var(--scrollTop)/ 2), 0, 0);
//     -webkit-transform: translate3d(calc(var(--scrollTop)/ 2), 0, 0);
//     -moz-transform: translate3d(calc(var(--scrollTop)/ 2), 0, 0);
//     -ms-transform: translate3d(calc(var(--scrollTop)/ 2), 0, 0);
//     -o-transform: translate3d(calc(var(--scrollTop)/ 2), 0, 0);
// }

.layer-mid-3 {
    transform: translate3d(calc(var(--scrollTop)/ -5.6), 0, 0);
    -webkit-transform: translate3d(calc(var(--scrollTop)/ -5.6), 0, 0);
    -moz-transform: translate3d(calc(var(--scrollTop)/ -5.6), 0, 0);
    -ms-transform: translate3d(calc(var(--scrollTop)/ -5.6), 0, 0);
    -o-transform: translate3d(calc(var(--scrollTop)/ -5.6), 0, 0);
}


.message {
    position: fixed !important;
    top: 50%;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto !important;
}

.video-react .video-react-video,
.video-react-controls-enabled,
.video-react-poster {
    border-radius: 50px 0 50px 0 !important;

}

.video-react-poster {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
}

.video-react .video-react-big-play-button {
    border: 1px solid var(--white) !important;
    background-color: #ffffff4d !important;
    font-size: 55px !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 85px !important;
    width: 85px !important;
    border-radius: 50% !important;
    transition: all 0.4s;
}

video-react-controls-enabled .loading-btn {
    font-size: 18px;
    font-family: var(--semiBold);
    color: var(--white);
}


.spinner {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 3px solid;
    border-color: var(--themeColor) transparent;
    animation: spin 1s infinite ease-out;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.star-1 {
    position: absolute;
    width: 550px;
    left: 410px;
}

.video-react-poster {
    width: 100%;
    height: 350px !important;
}

video {
    width: 100%;
    height: 350px !important;
}

.video-react.video-react-fluid {
    height: 350px !important;
    padding: unset !important;
}



/***************** RTL DIrection*************************/

html[dir='rtl'] {
    position: relative !important;
    width: 100%;
    overflow-x: hidden;
}

body .rtl {
    position: relative !important;
    font-family: var(--arRegular);
    font-style: normal;
    font-size: 15px;
}

.rtl .about-house,
.rtl .house-bird,
.rtl .click-btn>svg,
.rtl .service-boy,
.rtl .slick-slider svg,
.rtl .contact-svg,
.rtl .bach-img img,
.rtl .about-vector,
.rtl .trainer-wrapper:after,
.rtl .trainer-img img,
.rtl .star-right,
.rtl .star-left,
.rtl .contact-vector-1,
.rtl .contact-vector-2,
.rtl .btn-text svg {
    transform: scaleX(-1) !important;
    -webkit-transform: scaleX(-1) !important;
    -moz-transform: scaleX(-1) !important;
    -ms-transform: scaleX(-1) !important;
    -o-transform: scaleX(-1) !important;
}


.rtl .nav-bar-list a,
.rtl .footer-list a,
.rtl .field-box label {
    font-family: var(--arSemiBold) !important;
}

.rtl .nav-bar-list a.active {
    font-family: var(--arExtraBold) !important;
}


.rtl .header-btn span,
.rtl .choice-content,
.rtl .contact-text,
.rtl .react-images__positioner {
    direction: ltr;
}

.rtl .banner-content {
    position: relative;
    left: auto;
    right: 100px;
    bottom: 100px;
}

.rtl .Banner-board {
    position: relative;
    left: auto;
    right: 0px;
    top: 25px;

    &>div {
        &>button {

            left: auto;
            right: 14px;
        }
    }
}

.rtl .about-vector {
    right: auto !important;
    left: 0;
}

.rtl .cCOcNw .house-bird {
    right: auto;
    left: -20px;

}

.rtl .video-thumb>span>img,
.rtl .trainer-wrapper {
    border-radius: 0 50px 0 50px !important;
}

.rtl .trainer-wrapper:after {
    right: auto !important;
    left: 0;

}

.rtl .trainer-wrapper {
    border-radius: 0 50px 0 50px !important;
    border-left: transparent !important;
    border-right: 1px solid white;
    border-top: 1px solid white;
}

.rtl .star-right {
    right: auto !important;
    left: 0;
}

.rtl .gallery-item-1>img {
    border-radius: 20px 20px 0 20px !important;
}

.rtl .gallery-item-2>img {
    border-radius: 0 20px 20px 0 !important;
}

.rtl .img-item-3>img {
    border-radius: 0 0 20px 0 !important;
}

.rtl .img-item-4>img {
    border-radius: 0 20px 0 20px !important;
}

.rtl .img-item-5>img {
    border-radius: 20px 0 20px 20px !important;
}

.rtl .img-item-6>img {
    border-radius: 20px 0 20px 20px !important;
}

.rtl .img-item-7>img {
    border-radius: 20px 20px 20px 0 !important;
}

.rtl .img-item-8>img {
    border-radius: 20px 20px 0 20px !important;
}

.rtl .contact-vector-1 {
    left: 0;
    right: auto !important;
}

.rtl .contact-vector-2 {
    left: auto !important;
    right: 0 !important;
}


.rtl .field-box input::placeholder,
.rtl textarea::placeholder {
    font-family: var(--arRegular);
}

.rtl .heading-mini {
    letter-spacing: 0 !important;
}

.rtl .loading-btn {
    font-family: var(--arRegular) !important;
}

.rtl .Toastify__toast-body>div {
    font-family: var(--arRegular) !important;
}

.rtl .video-react .video-react-video,
.rtl .video-react-controls-enabled,
.rtl .video-react-poster {
    border-radius: 0 50px 0 50px;
}

.rtl .trainer-img>Span {
    left: auto !important;
    right: 0;
}

.rtl .achived-content-wrapper {
    gap: 5px;
}

.rtl .house-bird {
    right: auto !important;
    left: -20px !important;
}

.rtl .choice-3>div {
    bottom: 50px !important;
}

// .rtl .star-1 {
//     position: relative;
//     right: 0;
//     left: 20px;
// }